import React from 'react';
import ApiProxyFactory from './ApiProxy';
import {PRICING_TYPE} from './pricingTypes';
import {ErrNoSpec, ErrCartNotAvailable} from './errors';
import {formatValidator} from './Utils';
import Tracking from './Tracking';
const {zip3} = require('./taiwan');
const config = require('./data.json');

const Context = React.createContext();
const apiProxy = new ApiProxyFactory({apiUrl: config.endpoint.apiHost});

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? '?' : '&'}${key}=${params[key]}`;
        return e;
      }, '');
  }
  return query;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Provider extends React.Component {
  constructor(props) {
    super(props);
    console.log('App initialization');
    this.state = {
      loading: false,
      autoLoginLoading: false,
      toastContent: null,
      modalContent: null,
      spec: null,
      productNames: [],
      promoItems: [],
      categories: [],
      hashtags: [],
      blogLabels: [],
      faqLabels: [],
      profile: null,
      cart: null,
      notifs: [],
      specsError: {},
    };

    this.actions = {
      setLoading: (loading) => this.setState({loading}),
      setAutoLoginLoading: (loading) =>
        this.setState({autoLoginLoading: loading}),
      setToast: (toastContent) => this.setState({toastContent}),
      setModal: (modalContent = null) => this.setState({modalContent}),

      getJwtToken: async () => {
        return apiProxy.get({
          path: `/api/user/jwt/`,
        });
      },

      autoLogin: async () => {
        let token;
        if (typeof window !== 'undefined') {
          token = window.localStorage.getItem('token');
        }
        if (!token) {
          throw new Error('no token');
        }

        if (token) {
          apiProxy.setToken(token);
          try {
            let resp = await apiProxy.get({
              path: `/api/user/profile/`,
            });
            this.setState({profile: resp});
          } catch (ex) {
            if (!(ex instanceof TypeError)) {
              apiProxy.setToken(null);
              window.localStorage.removeItem('token');
            }
            throw ex;
          }
        }
      },

      login: async ({username, password, gui_number}) => {
        try {
          let resp = null;
          if (gui_number) {
            resp = await apiProxy.post({
              path: `/api/user/ent/login/`,
              data: {
                gui_number,
                identity: username,
                password,
              },
            });
          } else {
            resp = await apiProxy.post({
              path: `/api/user/login/`,
              data: {
                password,
                ...(formatValidator.isEmail(username)
                  ? {email: username}
                  : {username}),
              },
            });
          }

          apiProxy.setToken(resp.token);
          window.localStorage.setItem('token', resp.token);
          this.setState({profile: resp});
        } catch (ex) {
          apiProxy.setToken(null);
          window.localStorage.removeItem('token');
          throw ex;
        }
      },

      logout: async () => {
        apiProxy.setToken(null);
        window.localStorage.removeItem('token');
        this.setState({
          profile: null,
          cart: null,
          notifs: [],
        });
      },

      getProfile: async () => {
        let resp = await apiProxy.get({
          path: `/api/user/profile/`,
        });

        this.setState({profile: resp});
        return resp;
      },

      editProfile: async (data) => {
        let formData = new FormData();
        delete data.user;
        for (let key in data) {
          formData.append(key, data[key]);
        }

        return apiProxy.formPut({
          path: '/api/user/profile/',
          formData,
        });
      },

      changePassword: async (data) => {
        return apiProxy.post({
          path: `/api/user/change_password/`,
          data,
        });
      },

      forgetPassword: async (data) => {
        return apiProxy.post({
          path: `/api/user/password/forgot/`,
          data,
        });
      },

      //驗證信箱
      validEmail: ({email}) => {
        //此api 也會 確認信箱有無已註冊

        return apiProxy.post({
          path: `/api/user/profile/validation/request/`,
          data: {
            identity: email,
          },
        });
      },

      register: async ({access_token, password}) => {
        return apiProxy.post({
          path: `/api/user/register/`,
          data: {
            access_token,
            password,
          },
        });
      },

      setEnterpriseRegister: (bool) => {
        window.localStorage.setItem('ent_register', bool);
      },

      isEnterpriseRegister: async () => {
        let value = await window.localStorage.getItem('ent_register');
        return value === 'true' ? true : false;
      },

      enterpriseRegister: async ({access_token, password, ...data}) => {
        return apiProxy.post({
          path: `/api/user/ent/register/`,
          data: {
            access_token,
            password,
            ...data,
          },
        });
      },

      setSpec: async (spec) => {
        try {
          this.setState({spec});

          let productNames = [];
          for (let t of PRICING_TYPE) {
            if (t === 'custom') {
              continue;
            }
            let products = spec[t];
            if (products) {
              productNames = productNames.concat(
                products.map((product) => product.name),
              );
            }
          }
          this.setState({productNames});
        } catch (err) {
          console.warn('get spec fail', err);
        }
      },

      getSpec: async (version) => {
        if (this.state.spec && version === this.state.spec.version) {
          return this.state.spec;
        } else if (version) {
          return await (
            await fetch(`${config.endpoint.specUrl}/${version}.json`)
          ).json();
        } else {
          const currentTime = Date.now();

          return await (
            await fetch(
              `${config.endpoint.specUrl}/latest.json?v=${currentTime}`,
            )
          ).json();
        }
      },

      getProductFromSpec: ({productName, spec = null}) => {
        // maybe previous version
        let _spec = spec || this.state.spec;

        if (!_spec) {
          return new ErrNoSpec();
        }

        for (let t of PRICING_TYPE) {
          const product = _spec[t].find((p) => p.name === productName);
          if (product) {
            return product;
          }
        }

        return null;
      },
      getProducts: async () => {
        const resp = await apiProxy.get({
          path: `/api/product/`,
          secure: false,
        });
        return resp.results;
      },

      getProduct: async (id) => {
        return apiProxy.get({
          path: `/api/product/${id}`,
          secure: false,
        });
      },

      getProductByName: async (name) => {
        let resp = await apiProxy.get({
          path: `/api/product/?name=${encodeURIComponent(name)}`,
          secure: false,
        });

        return resp.results.length ? resp.results[0] : null;
      },

      calcPrice: async (data) => {
        return apiProxy.post({
          withHost: true,
          path: `${config.endpoint.calcPriceUrl}/product/calc-price?client_id=${config.client}`,
          data,
        });
      },

      getPromoItems: async () => {
        // type = banner || top_zone || bottom_zone
        try {
          let promoItems = await apiProxy.get({
            path: `/api/promo_item/`,
          });

          this.setState({
            promoItems: promoItems.sort((a, b) => {
              if (a.priority === null) {
                return 1;
              } else if (b.priority === null) {
                return -1;
              }
              return a.priority - b.priority;
            }),
          });
        } catch (err) {}
      },

      getCategories: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${config.endpoint.revJstorageHost}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'products'}},
          });

          this.setState({categories: resp.children});
        } catch (err) {
          console.warn('Fail: Get Categories');
        }
      },

      getHashtags: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${config.endpoint.revJstorageHost}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'hashtags'}},
          });

          this.setState({hashtags: resp.children});
        } catch (err) {
          console.warn('Fail: Get Hashtags');
        }
      },

      //#region CART
      getCart: async () => {
        try {
          let resp = await apiProxy.get({
            path: '/api/cart/',
          });
          this.setState({cart: resp.cart});
        } catch (ex) {
          console.warn('DBG', ex);
        }
      },

      addItem: async (item) => {
        if (!this.state.cart) {
          throw new ErrCartNotAvailable();
        }

        const event_id =
          typeof crypto?.randomUUID === 'function'
            ? crypto.randomUUID()
            : new Date().toISOString();

        let resp = await apiProxy.post({
          path: '/api/cart2/add_item/',
          data: {
            ...item,
            version: this.state.spec.version,
            event_id,
          },
        });
        this.setState({cart: resp.cart});

        Tracking.addToCart(item, this.state.profile?.id, event_id);
      },

      updateItem: async (index, item) => {
        let resp = await apiProxy.post({
          path: '/api/cart2/edit_item/',
          data: {index, config: item.config, version: this.state.spec.version},
        });
        this.setState({cart: resp.cart});
      },

      removeItem: async (index) => {
        let resp = await apiProxy.post({
          path: '/api/cart2/delete_item/',
          data: {index},
        });

        Tracking.removeFromCart(this.state.cart, index);

        this.setState({cart: resp.cart});
      },

      editConfig: async (config) => {
        let resp = await apiProxy.post({
          path: '/api/cart/edit_config/',
          data: {
            config: JSON.stringify(config),
          },
        });
        this.setState({cart: resp.cart});
      },

      checkout: async (data) => {
        return apiProxy.post({
          path: '/checkout/order/',
          data: {...data},
        });
      },

      getCoupon: async (code) => {
        return apiProxy.get({
          path: `/api/coupon/${code}`,
        });
      },

      calcOrder: async (data) => {
        // estimate cart calculation
        let resp = await apiProxy.post({
          path: `/api/order/calc/`,
          data,
        });
        this.setState({cart: resp});
        return resp;
      },
      //#endregion CART

      //#region ORDER
      getOrders: async (params = {}) => {
        params.ordering = '-created';
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/checkout/order/${query}`,
        });
      },

      getOrder: async (id) => {
        return apiProxy.get({
          path: `/checkout/order/${id}/`,
        });
      },

      editOrderBuyer: async ({id, buyer}) => {
        return apiProxy.put({
          path: `/api/order/${id}/`,
          data: {
            buyer_id: buyer,
          },
        });
      },

      editOrderDownloaded: async ({id, ...data}) => {
        return apiProxy.put({
          path: `/api/order/downloaded/${id}/`,
          data,
        });
      },

      getOrderItems: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/order_item/${query}`,
        });
      },

      editOrderItem: async ({id, ...data}) => {
        return apiProxy.put({
          path: `/api/order_item/${id}/`,
          data,
        });
      },

      getLogistics: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/logistics/${query}`,
        });
      },

      addAttatchment: async (data) => {
        return apiProxy.post({
          path: `/api/order_item/attachment/`,
          data,
        });
      },

      getInvoices: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/issue/invoice/${query}`,
        });
      },

      getRefunds: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/refund/${query}`,
        });
      },

      editRefund: async (data) => {
        return apiProxy.put({
          path: `/api/refund/${data.id}/`,
          data,
        });
      },

      getReturnApps: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/return_app/${query}`,
        });
      },

      createReturnApp: async (params) => {
        let formData = new FormData();
        for (let key in params) {
          if (params[key] !== undefined && params[key] !== null) {
            formData.append(key, params[key]);
          }
        }
        return apiProxy.formPost({
          path: `/api/return_app/`,
          formData,
        });
      },

      voidOrder: async ({id, void_reason}) => {
        return apiProxy.post({
          path: `/checkout/order/${id}/void/`,
          data: {
            void_reason,
          },
        });
      },

      createCreditsOrder: async (credits) => {
        return apiProxy.post({
          path: '/api/buy_credits/',
          data: {
            credits,
          },
        });
      },

      getPromotionFeedback: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/promotion/feedback/${query}`,
        });
      },

      getPromotions: async () => {
        return apiProxy.get({
          path: `/api/promotion/diff/`,
        });
      },

      getCustomCalculations: async (params) => {
        return apiProxy.post({
          path: `/api/order/custom/calc/`,
          data: params,
        });
      },

      createCustomOrder: async (params) => {
        return apiProxy.post({
          path: `/api/order/custom/`,
          data: params,
        });
      },

      editOrderRemit: async ({id, ...data}) => {
        return apiProxy.post({
          path: `/checkout/order/remit/${id}/`,
          data,
        });
      },

      getPeriodCheckoutUrl: async (data) => {
        if (!apiProxy.apiToken) {
          throw new Error('找不到 token');
        }
        return apiProxy.get({
          path: `/checkout/neweb/period/request`,
          extraHeaders: {'Cache-Control': 'no-cache'},
        });
        // return `${apiProxy.apiUrl}/checkout/neweb/period/request?token=${apiProxy.apiToken}`
      },

      getPeriod: async (id) => {
        return apiProxy.get({
          path: `/api/period/order/${id}`,
          secure: true,
        });
      },

      editPeriodNote: async ({id, note}) => {
        return apiProxy.post({
          path: `/api/period/order/update/${id}/`,
          data: {
            note,
            // status will be modified to review_waiting by backend
          },
        });
      },

      //#endregion ORDER

      //#region monthly
      getMonthlyOrders: async (data) => {
        let query = transToQueryStr(data);
        return apiProxy.get({
          path: `/api/monthly/order/${query}`,
        });
      },

      getMonthlyOrder: async (id) => {
        return apiProxy.get({
          path: `/api/monthly/order/${id}`,
        });
      },

      editMonthlyRemit: async ({id, ...data}) => {
        return apiProxy.put({
          path: `/api/monthly/order/user/${id}`,
          data,
        });
      },

      //#endregion monthly

      //#region  BLOG
      getBlogLabels: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${config.endpoint.revJstorageHost}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'articles'}},
          });

          this.setState({blogLabels: resp.children});
        } catch (err) {
          console.warn('Fail: Get Blog Labels');
        }
      },

      getFaqLabels: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${config.endpoint.revJstorageHost}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'faqs'}},
          });

          this.setState({faqLabels: resp.children});
        } catch (err) {
          console.warn('Fail: Get Faq Labels');
        }
      },

      getBlogs: async (data) => {
        return apiProxy.post({
          path: `${config.endpoint.revJstorageHost}/document/Article_Default/find?client_id=${config.client}`,
          withHost: true,
          data,
        });
      },

      getBlog: async (data) => {
        return apiProxy.post({
          path: `${config.endpoint.revJstorageHost}/document/Article_Default/find-one?client_id=${config.client}`,
          withHost: true,
          data,
        });
      },

      //#endregion BLOG

      addNotif: (notif) =>
        this.setState((state) => ({notifs: [...state.notifs, notif]})),

      clearNotifs: () => this.setState({notifs: []}),

      getUsers: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/user/profile/all/${query}`,
        });
      },

      reqUpgradeUserType: async (data) => {
        return apiProxy.post({
          path: `/auth/user/upgrade`,
          data,
        });
      },

      getUpgrade: async () => {
        let {id, user_type} = this.state.profile;

        let {results} = await apiProxy.get({
          path: `/auth/user/upgrade/list?issuer=${id}`,
        });

        let record = results.find((x) => x.state == 'pending');
        if (record) {
          // detect if there is any pending record first, otherwise, recognized user type
        } else if (user_type === 'normal') {
          record = results.find((x) => x.req_to === 'enterprise');
        } else if (user_type === 'vip') {
          record = results.find((x) => x.req_to === 'ent_vip');
        } else if (user_type === 'enterprise') {
          record = results.find((x) => x.req_to === 'monthly');
        } else if (user_type === 'ent_vip') {
          record = results.find((x) => x.req_to === 'ent_vip_monthly');
        }

        return record;
      },

      getHistories: async (data, jwtToken) => {
        return apiProxy.post({
          path: `${config.endpoint.revJstorageHost}/document/history/find?token=${jwtToken}`,
          withHost: true,
          data,
        });
      },

      contact: async (data) => {
        if (!data.data) {
          delete data.data;
        }
        return apiProxy.post({
          path: `/api/contact/`,
          data,
        });
      },

      getUploadPresignUrl: async (file, options = {}, jwtToken) => {
        const fileKey = file.name.split('.')[0];
        const fileType = file.type;
        const {acl = 'public-read'} = options;

        return apiProxy.post({
          path: `${
            config.endpoint.revStorageHost
          }/storage/presigned/url?client_id=${config.client}${
            jwtToken ? '&token=' + jwtToken : ''
          }`,
          withHost: true,
          secure: false,
          data: {
            acl,
            'Content-Type': fileType,
            key: `${fileKey}`,
          },
        });
      },

      getPrivateFile: async (key, jwtToken) => {
        return apiProxy.post({
          path: `${config.endpoint.revStorageHost}/storage/read?token=${jwtToken}`,
          withHost: true,
          secure: false,
          data: {
            filename: key,
          },
        });
      },

      //#region address
      getCities: () => {
        return [...new Set(zip3.map((x) => x.city))].map((x) => ({
          countyName: x,
        }));
        return apiProxy.get({
          path: `https://daiwanlang.netlify.app/api/行政區/get`,
          withHost: true,
          secure: false,
        });
      },

      getDistricts: (county) => {
        return zip3
          .filter((x) => x.city === county)
          .map((x) => ({townName: x.district, zipCode: x.zip}));
        return apiProxy.get({
          path: `https://daiwanlang.netlify.app/api/行政區/${county}/get`,
          withHost: true,
          secure: false,
        });
      },

      getZipCode: (zipcode) => {
        let result = zip3.find((x) => x.zip === zipcode);
        if (result) {
          return {
            countyName: result.city,
            townName: result.district,
          };
        }
        // return apiProxy.get({
        //   path: `https://daiwanlang.netlify.app/api/行政區/郵遞區號/${zipcode}/get`,
        //   withHost: true,
        //   secure: false,
        // })
      },
      //#endregion address

      getCvsUrl: () =>
        `${config.endpoint.calcPriceUrl}/cvs?client_id=${config.client}`,

      getReurl: ({id, image, title, outline}) =>
        `${
          config.endpoint.apiHost
        }/api/reurl?image=${image}&title=${title}&redirect_url=${
          config.siteUrl
        }/article?id=${id}${outline ? `&description=${outline}` : ``}`,

      setSpecsError: (field, isError) => {
        this.setState((prevState) => ({
          specsError: isError
            ? {...prevState.specsError, [field]: isError}
            : Object.keys(prevState.specsError).reduce((acc, key) => {
                if (key !== field) {
                  acc[key] = prevState.specsError[key];
                }
                return acc;
              }, {}),
        }));
      },
    };
  }

  async componentDidMount() {
    this.actions.getPromoItems();
    this._initSpec();

    this.actions.getCategories();
    this.actions.getHashtags();
    this.actions.getBlogLabels();
    this.actions.getFaqLabels();
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          actions: this.actions,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }

  _initSpec = async () => {
    let spec = await this.actions.getSpec();
    this.actions.setSpec(spec);
  };
}

export {Context, Provider};
