module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"西北影像股份有限公司","short_name":"印刷吧 Print Bar","start_url":"/","background_color":"#1E739E","theme_color":"#1E739E","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8342c4545916f0af4cf58875c6169239"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"370712871416192"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
